import { useState, useEffect } from 'react';

const Contadores = () => {
  // Estados para os números
  const [num1, setNum1] = useState(0);
  const [num2, setNum2] = useState(0);
  const [num3, setNum3] = useState(0);

  // Função para animar o contador
  const animateNumber = (start, end, setter, duration = 750) => {
    let startTime = null;
    const step = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const progress = timestamp - startTime;
      const value = Math.min(Math.floor(progress / duration * (end - start) + start), end);
      setter(value);
      if (progress < duration) {
        window.requestAnimationFrame(step);
      }
    };
    window.requestAnimationFrame(step);
  };

  // Iniciar contagem quando o componente é carregado
  useEffect(() => {
    animateNumber(0, 600000, setNum1, 3000);  // 600.000m2
    animateNumber(0, 2000000000, setNum2, 3000); // R$2 bi
    animateNumber(0, 8000, setNum3, 3000);   // 8.000 empregos
  }, []);

  return (
    <>
      {/* Botões */}
      <div className="grid sm:grid-rows-2 lg:grid-rows-1 lg:grid-cols-2 " id="btnHolder">
        {/* Col 1 */}
        <button className=" lg:col-start-1 sm:row-start-1 bg-BTSBabyBlue p-5 text-2xl font-bold cursor-pointer" 
        onClick={() => {
          const targetElement = document.getElementById('Empreendimentos');
          if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
          }
        }}
       >
          Conheça Nossos Projetos
        </button>
        {/* Col 2 */}
        <button className=" lg:col-start-2 lg:row-start-1 sm:row-start-2 bg-BTSDarkGrey p-5 text-2xl font-bold "
         onClick={() => {
          const targetElement = document.getElementById('FaleConosco');
          if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth' });
          }
        }}
        >
          Seja Nosso Parceiro
        </button>
      </div>

      {/* Numeros */}
      <div className="bg-BTSOlive w-full py-10 grid lg:grid-cols-3 lg:grid-rows-2 sm:grid-rows-6 justify-center">
        {/* Col 1 */}
        <div id="col1row1" className="row-start-1 text-4xl lg:text-5xl ">
          + {num1.toLocaleString()}m²
        </div>
        <div id="col1row2" className="row-start-2 text-2xl ">
         de operações desenvolvidas.
        </div>

        {/* Col 2 */}
        <div id="col2row1" className="row-start-3 lg:row-start-1 text-3xl lg:text-5xl mt-5 lg:mt-0">
          + R${num2.toLocaleString()}
        </div>
        <div id="col2row2" className="row-start-4 lg:row-start-2 text-2xl">
          de operações desenvolvidas
        </div>

        {/* Col 3 */}
        <div id="col3row1" className="row-start-5 lg:row-start-1 text-4xl lg:text-5xl mt-5 lg:mt-0">
          + {num3.toLocaleString()}
        </div>
        <div id="col3row2" className="row-start-6 lg:row-start-2 text-2xl">
          empregos gerados
        </div>
      </div>
    </>
  );
};

export default Contadores;
