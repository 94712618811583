import React, { useState, useEffect } from "react";
import { FaCircleArrowRight } from "react-icons/fa6";
import { FaCircleArrowDown } from "react-icons/fa6";
const steps = [
  { id: 1, image: "passo1.png", title: "Definição da localização e seleção dos terrenos" },
  { id: 2, image: "passo2.png", title: "Desenvolvimento e estudo de implantação" },
  { id: 3, image: "passo3.png", title: "Definição das condições comerciais" },
  { id: 4, image: "passo4.png", title: "Assinatura do contrato" },
  { id: 5, image: "passo5.png", title: "Aprovação e execução da obra" },
];

const Workflow = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    
    <div className="relative">
      {/* Fundo dividido */}
      <div className="absolute inset-0 lg:h-1/2 h-full bg-BTSOlive b-0 m-0  " />
      <div className="lg:absolute lg:inset-0 lg:top-1/2 lg:bg-white bg-BTSOlive b-0 m-0  " />

      <div className="relative py-8 flex flex-col items-center justify-center min-h-screen ">
        <div className="text-center mb-60">
          <h2 className="m-10 pb-10 text-4xl text-white">
            Como a <span className="font-extrabold">BTS</span> trabalha
          </h2>
        </div>
        <div
          className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-20 px-4 mt-[-200px]" 
        >
          {steps.map((step, index) => (
            <div
              key={step.id}
              className="relative flex flex-col items-center bg-white shadow-md rounded-lg p-6 text-center text-gray-700"
              style={{ width: "200px", height: "250px" }} 
            >
              <div className="mb-4">
                <img src={step.image} alt={`Passo ${step.id}`} className="w-20 h-20 mx-auto" />
              </div>
              <p className="text-sm">{step.title}</p>
              {index !== steps.length - 1 && (
                <div className="absolute lg:top-1/2 lg:right-[-50px] bottom-0 transform translate-y-14 lg:translate-y-0">
                  <span className="lg:text-gray-400  text-white text-4xl text-center">{isSmallScreen ?<FaCircleArrowDown/>:<FaCircleArrowRight/>}</span>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Workflow;
