import React from 'react';

const Conheca = () => {
    return (
        <section className="m-10 pb-10 bg-white" id="ConhecaBTS">
            <div className="max-w-4xl mx-auto text-center">

                {/* Título */}
                <h1 className="lg:pb-20 pb-10 lg:text-4xl text-2xl text-black font-light">Conheça a <span className="text-black font-bold">BTS Properties</span></h1>

                {/* Paragrafo 1 */}
                <p className="text-lg text-black leading-relaxed mb-8">
                    <span className="font-light">A BTS Properties, é uma plataforma de </span><span className="italic font-bold">desenvolvimento de projetos imobiliários </span><span className="font-light">que conecta imóveis construídos sob medida à investidores do mercado, especialmente por meio de operações de </span>
                    <span className="italic font-bold">Built-to-Suit</span>.
                </p>

                {/* Paragrafo 2 */}
                <p className="text-lg text-black leading-relaxed mb-8">
                    <span className="font-light">Com foco em atender as especificidades dos nossos inquilinos e a proporcionar solidez aos nossos parceiros, </span><span className="font-bold italic">geramos valor e previsibilidade </span><span>para todo processo.</span>
                </p>

                {/* Paragrafo 3 */}
                <p className="text-lg text-black leading-relaxed">
                    <span className="font-light">Fundada em 2013 na cidade de Belo Horizonte, a BTS foi uma das primeiras empresas do país focadas no segmento, sendo hoje reconhecida como um dos  </span><span className="italic font-bold">principais players do mercado</span>.
                </p>
            </div>
        </section>
    );
};

export default Conheca;
