import SlideView from "./functional/SlideView";

const DetalhesEmpreendimento = ({asset,setSelectedAsset})=>{

return(
    <div className="bg-white max-h-full p-3" >
    <div id="Title" className="w-full text-left px-10 flex  p-1">
        <span className="bg-gray-800 px-2 py-4"/>
        <div className="flex flex-col ps-3">
        <span className="block text-3xl text-gray-500">{asset.asset_name}</span>
        <span className="font-bold text-gray-600">{asset.asset_type}</span>
        </div>
    </div>
    <div id="ImageGalery" className="flex justify-center w-full">
    <div className="w-full">
    <SlideView slides={asset.asset_galery_images}/>
    </div>
    </div>
    <div id="Description" className="flex justify-center text-black font-normal text-justify px-10 text-sm mt-3">
        {asset.asset_details}
    </div>
    <div id="Asset Data" className="px-10 py-3">
   <div className="grid lg:grid-cols-3 grid-rows-2">
    <div id="ABL">

    </div>
    <div id="ABL" className="text-black lg:col-start-1">
    <div className="row-start-1 font-normal">Área Bruta Locável(ABL)</div>
    <div className="row-start-2 text-md text-BTSBabyBlue">{asset.abl}</div>
    </div>
    <div id="Area Construida" className="text-black lg:col-start-2">
    <div className="row-start-1 font-normal">Área Construída Total</div>
    <div className="row-start-2 text-md text-BTSBabyBlue">{asset.total_area}</div>
    </div>
    <div id="Area complementar" className="text-black lg:col-start-3">
    <div className="row-start-1 font-normal"> {asset.complementary_area_title}</div>
    <div className="row-start-2 text-md text-BTSBabyBlue">{asset.complementary_area}</div>
    </div>
   </div>
    </div>
    {asset.asset_uppperView && (
    <div id="UpperView" className="flex justify-center my-2">
       
        <img src={asset.asset_uppperView} className="w-96"/>   
    </div>
    )
}
    <div id="MapAddress" className="text-gray-500 font-bold">
   <div className="flex justify-center">
   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-8">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
  <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z" />
</svg>

    </div>
    <div className="flex justify-center">
    <a href={asset.map_link}>{asset.map_address}</a>
    </div>
    </div>
    <div id="closeBtn">
        <button className="bg-gray-600 text-white rounded-full lg:px-10 py-3 font-bold lg:w-96 w-64 mt-3" onClick={()=>setSelectedAsset(null)}>Fechar</button>
    </div>
    </div>
)

}

export default DetalhesEmpreendimento;