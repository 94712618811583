import { useState } from "react";
import Assets from "../Data/Assets";
import DetalhesEmpreendimento from "./DetalhesEmpreendimento";

const Empreendimentos = () => {
  const [hoverIndex, setHoverIndex] = useState(null);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const viewDetails = (asset) => {
    setSelectedAsset(asset);
  };

  const closePopup = () => {
    setSelectedAsset(null);
  };

  const handleNext = () => {
    setCurrentSlide((prev) => (prev + 1) % Assets.length);
  };

  const handlePrev = () => {
    setCurrentSlide((prev) => (prev - 1 + Assets.length) % Assets.length);
  };

  return (
    <div id="Empreendimentos">
      {/* Grid layout for larger screens */}
      <div className="hidden lg:grid lg:grid-cols-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-rows-2 gap-0" id="empreendimentos">
        {Assets.map((asset, index) => (
          <div
            key={index}
            className="relative group overflow-hidden shadow-lg"
            onMouseEnter={() => setHoverIndex(index)}
            onMouseLeave={() => setHoverIndex(null)}
          >
                  
{/* Triângulo com texto de status 
<div className="absolute top-0 left-0 w-32 h-32 bg-green-800 clip-triangle z-50 flex items-center justify-center">
  <div className="flex w-full h-full justify-center">
<span className="absolute bottom-4 left-0 text-white text-xs font-bold transform -rotate-45 origin-top-left text-center">
    {asset.asset_status}
  </span>
  </div>
</div>
*/}
{/* Faixa diagonal no canto superior esquerdo com texto de status */}
<div className="absolute top-0 left-0 w-full h-full z-50">
  <div className={`absolute top-10 left-[-3rem] w-56 h-12 bg-${asset.asset_status_color} transform -rotate-45 flex items-center justify-center`}>
    <span className="text-white text-xs font-bold px-10 ">
      {asset.asset_status}
    </span>
  </div>
</div>

            <img
              src={asset.asset_image}
              alt={asset.asset_name}
              className="w-full h-96 object-cover contrast-50 group-hover:scale-110 group-hover:contrast-150 transition-transform duration-300"
            />
            <div className="absolute inset-0 bg-black bg-opacity-40 flex flex-col items-center justify-center text-white cursor-default">
              <div className="flex flex-row group-hover:scale-110 transition-transform duration-300">
                <span className="bg-gray-300 py-2 px-1 h-12 mr-2 group-hover:bg-transparent transition-transform duration-500"></span>
                <div className="flex flex-col">
                  <h2 className="text-2xl font-normal group-hover:bg-gray-300 group-hover:font-black px-2 transition-transform duration-800">
                    {asset.asset_title}
                  </h2>
                  <p
                    className="font-bold group-hover:bg-white group-hover:cursor-pointer group-hover:text-gray-500 px-2 transition-transform duration-800 group-hover:my-2"
                    onClick={() => viewDetails(asset)}
                  >
                    {hoverIndex === index ? "Veja o Projeto" : asset.asset_city + "-" + asset.asset_state}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Slideshow for smaller screens */}
      <div className="lg:hidden relative w-full">
        <div className="flex flex-col items-center">
          <div className="relative group overflow-hidden shadow-lg w-full h-96"   >
                            {/* Faixa diagonal no canto superior esquerdo com texto de status */}
<div className="absolute top-0 left-0 w-full h-full ">
  <div className={`absolute top-10 left-[-3rem] w-56 h-12 bg-${Assets[currentSlide].asset_status_color} transform -rotate-45 flex items-center justify-center  z-10`}>
    <span className="text-white text-sm font-bold px-10 ">
      {Assets[currentSlide].asset_status}
    </span>
  </div>
</div>
            <img
              src={Assets[currentSlide].asset_image}
              alt={Assets[currentSlide].asset_name}
              className="w-full h-96 object-cover contrast-50"
            />
            
            <div className="absolute inset-0 bg-black bg-opacity-40 flex flex-col items-center justify-center text-white cursor-default z-0" >
             <button  onClick={() => viewDetails(Assets[currentSlide])} className="z-50">
              <h2 className="text-2xl font-normal mb-2">{Assets[currentSlide].asset_title}</h2>
              <p
                className="font-bold cursor-pointer text-gray-200"
               
              >


                {Assets[currentSlide].asset_city + "-" + Assets[currentSlide].asset_state}
              </p>
              </button>
            </div>
          </div>
        </div>

        {/* Navigation Buttons */}
        <div className="absolute inset-0 flex justify-between items-center px-4">
          <button
            onClick={handlePrev}
            className="text-gray-500 bg-gray-200 rounded-full w-10 h-10 flex justify-center items-center"
          >
            &lt;
          </button>
          <button
            onClick={handleNext}
            className="text-gray-500 bg-gray-200 rounded-full w-10 h-10 flex justify-center items-center"
          >
            &gt;
          </button>
        </div>
      </div>

      {/* Popup for asset details */}
      {selectedAsset && (
        <div className="bg-black bg-opacity-50 flex items-center justify-center z-50 fixed inset-0 p-5">
          <div className="bg-white py-4 px-4 rounded-lg shadow-lg relative w-10/12 max-h-[90vh] overflow-y-auto">
            <button
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-800 text-4xl"
              onClick={closePopup}
            >
              &times;
            </button>
            <DetalhesEmpreendimento asset={selectedAsset} setSelectedAsset={setSelectedAsset} />
          </div>
        </div>
      )}
     <div 
  className="py-3 bg-BTSDarkGrey font-black cursor-pointer" 
  onClick={() => {
    const targetElement = document.getElementById('FaleConosco');
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  }}
>
  QUER SER UM PARCEIRO DA BTS?
</div>
    </div>
  );
};

export default Empreendimentos;
