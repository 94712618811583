import { useEffect, useRef } from 'react';

const Clientes = () => {
  const logos = [
  
    { src: './clients/1 (2).png' },
    { src: './clients/2 (2).png' },
    { src: './clients/3 (2).png' },
    { src: './clients/4 (2).png' },
    {src:'./clients/vale.png'},
    { src: './clients/5 (2).png' },
    { src: './clients/6 (2).png' },
    { src: './clients/7 (2).png' },
    { src: './clients/8 (2).png' },
    { src: './clients/9 (2).png' },
    { src: './clients/kroton.png' },
    { src: './clients/l-bh (1).png' },
    { src: './clients/l-bk (1).png' },
    {src:'./clients/pepisico.png'},
    { src: './clients/l-dafiti (1).png' },
    { src: './clients/l-pana (1).png' },
    { src: './clients/l-telha (1).png' },
    { src: './clients/Logos Site.png' },
    { src: './clients/maplebear.png' },
    { src: './clients/saintgobain.png' },
  
   
   
  ];

  const sliderRef = useRef(null);

  useEffect(() => {
    const slider = sliderRef.current;

    // Clone os itens para criar um loop contínuo
    const cloneLogos = () => {
      const sliderContent = slider.innerHTML;
      slider.innerHTML += sliderContent;
    };

    // Inicia a animação após clonar os itens
    const startAnimation = () => {
      slider.style.animation = 'scroll 20s linear infinite';
    };

    cloneLogos();
    startAnimation();

    return () => {
      slider.style.animation = '';
    };
  }, []);

  return (
    <div className="w-full pt-5">
      <span className="pb-5 block text-center font-semibold text-slate-600 ">
        Alguns de nossos clientes
      </span>
      <div
        className="relative overflow-hidden w-full"
        style={{ height: '120px' }} // Altura do carrossel
      >
        <div
          className="flex whitespace-nowrap"
          ref={sliderRef}
          style={{
            animation: '',
            display: 'flex',
            gap: '16px',
          }}
        >
          {logos.map((logo, index) => (
            <img
              key={index}
              src={logo.src}
              alt={`Cliente ${index + 1}`}
              className="w-auto h-auto"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Clientes;
