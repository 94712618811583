import { useState } from "react";

const Depoimentos = () => {
  const testimonials = [
    {
      image: "./testimonials/depoimento.jpg",
      name: "Thibaud Lecuyer",
      role: "Co-Founder of Dafiti",
      text: `Escolher a BTS para fazer nosso novo CD foi uma aposta devido a experiência deles, 
        mas decidimos ir com eles porque desde o início se mostraram verdadeiros parceiros do nosso projeto e nosso time. 
        Foram grandes apoiadores nas soluções e adversidades que sempre acontecem com projetos deste tamanho. 
        Eu recomendo!`,
    },
    {
      image: "./testimonials/leonardo-byrro.jpg",
      name: "Leonardo Byrro",
      role: "CEO Grupo Mafra",
      text: `Participamos do começo da História da BTS e desde o começo nos chamou atenção a dedicação, seriedade e parceria de todo o time. 
        Nosso primeiro empreendimento foi feito em tempo recorde e até nosso time duvidava que seria possível. 
        Para nós é um prazer ter feito parte do crescimento deles e continuamos com nossa parceria. 
        Grupo Mafra-Cremer recomenda.`,
    },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);

  const handleNext = () => {
    setCurrentSlide((prev) => (prev + 1) % testimonials.length);
  };

  const handlePrev = () => {
    setCurrentSlide((prev) => (prev - 1 + testimonials.length) % testimonials.length);
  };

  return (
    <div className="py-12 px-5 bg-gray-50">
      {/* Grid for larger screens */}
      <div className="hidden lg:grid lg:grid-cols-2 gap-8">
        {testimonials.map((testimonial, index) => (
          <div key={index} className="px-5 mx-5">
            <div className="flex justify-center">
              <span className="w-64 border-[16px] rounded-full">
                <img
                  src={testimonial.image}
                  alt={testimonial.name}
                  className="w-56 h-56 rounded-full border-gray-300 object-cover"
                />
              </span>
            </div>
            <div className="text-center mt-2">
              <span className="text-gray-900 font-bold text-xl">{testimonial.name}</span>
              <br />
              <span className="text-gray-400 font-normal">{testimonial.role}</span>
            </div>
            <div className="mt-3 text-slate-500 text-md leading-10 font-normal text-center px-10">
              {testimonial.text}
            </div>
          </div>
        ))}
      </div>

      {/* Slideshow for smaller screens */}
      <div className="lg:hidden relative w-full">
        <div className="flex flex-col items-center">
          <div className="w-64 border-[16px] rounded-full mb-4">
            <img
              src={testimonials[currentSlide].image}
              alt={testimonials[currentSlide].name}
              className="w-56 h-56 rounded-full border-gray-300 object-cover"
            />
          </div>
          <div className="text-center">
            <span className="text-gray-900 font-bold text-xl">{testimonials[currentSlide].name}</span>
            <br />
            <span className="text-gray-400 font-normal">{testimonials[currentSlide].role}</span>
          </div>
          <div className="mt-3 text-slate-500 text-md leading-10 font-normal text-center px-10">
            {testimonials[currentSlide].text}
          </div>
        </div>

        {/* Navigation Buttons */}
        <div className="absolute inset-0 flex justify-between items-center px-4">
          <button
            onClick={handlePrev}
            className="text-gray-500 bg-gray-200 rounded-full w-10 h-10 flex justify-center items-center"
          >
            &lt;
          </button>
          <button
            onClick={handleNext}
            className="text-gray-500 bg-gray-200 rounded-full w-10 h-10 flex justify-center items-center"
          >
            &gt;
          </button>
        </div>
      </div>
    </div>
  );
};

export default Depoimentos;
