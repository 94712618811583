
import Header from "../Components/Header";
import Contadores from "../Components/Contadores";
import Conheca from "../Components/Conheca";
import Footer from "../Components/Footer";
import Clientes from "../Components/Clientes";
import Depoimentos from "../Components/Depoimentos";
import Empreendimentos from "../Components/Empreendimentos";
import Contato from "../Components/Contato";
import Denuncia from "../Components/Denuncia";
import Duvidas from "../Components/Duvidas";
import Workflow from "../Components/Workflow";
import { FaWhatsapp } from 'react-icons/fa';
const HomePage = ()=>{


return (
<div className= "text-white font-bold max-h-full">
    
<header>
<Header/>
</header>
<Contadores/>
<Clientes/>
<Depoimentos/>
<Empreendimentos/>
<Conheca/>
<Workflow/>
<Contato/>
<Denuncia/>
<footer>
<Footer/>    
</footer>
<a 
        href="https://wa.me/5531999980078" 
        target="_blank"
        rel="noopener noreferrer"
        className="fixed bottom-3 lg:bottom-5 right-5 bg-green-500 text-white p-3 rounded-full shadow-lg hover:bg-green-600"
      >
        <FaWhatsapp size={30} />
      </a>


</div>

);
}

export default HomePage;
