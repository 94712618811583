const Assets = [

  {
    asset_id: 13,
    asset_name: "CLCT Quintas",
    asset_title: "CLCT 01",
    asset_status: "Módulos disponiveis!",
    asset_status_color:'BTSOlive',
    asset_city: "Contagem",
    asset_state: "MG",
    asset_details: `
      Primeiro empreendimento em caráter especulativo do nosso grupo, o CLCT é um galpão AAA e o primeiro da região a contar com selo de sustentabilidade LEED Gold. Imóvel desenvolvido com capacidade para atender as mais importantes operações logísticas do país. Hoje conta com a presença de empresas como: Mercado Livre, Vale e PepsiCo.

Disponibilidade Imediata

Modulações a partir de 3 mil m² de estocagem
      `,
    asset_type: "Condomínio Logístico CLCT",
    abl: '65.000m²',
    total_area: '182.000 m²',
    complementary_area: '',
    complementary_area_title: '',
    map_address: 'Rua das Contendas, 1807 – Chácara Campestre – Contagem/MG',
    map_link: "https://maps.app.goo.gl/v48hFsdUdDhGW9J58",
    asset_image: "empreendimentos/CLCT-1.jpg",
    asset_uppperView: null,
    asset_galery_images: [
      "empreendimentos/CLCT-1.jpg",
      "empreendimentos/CLCT-2.jpg",
      "empreendimentos/CLCT-3.jpg",
      "empreendimentos/CLCT-4.jpg",
      "empreendimentos/CLCT-5.jpg",
      "empreendimentos/CLCT-PLANTA.jpg",
    ]
  },
  {
    asset_id: 17,
    asset_name: "CLRE01",
    asset_title: "CLRE 01",
    asset_status:"Pré locação disponível!",
    asset_status_color:'BTSOlive',
    asset_city: "Jaboatão dos Guarapes",
    asset_state: "PE",
    asset_details: `
     Primeiro empreendimento BTS na região nordeste, o CLRE irá compor a malha logística pernambucana se equiparando aos mais avançados imóveis logísticos do estado e da região nordeste. Com localização estratégica à beira da BR 101, 10 minutos do Aeroporto Internacional de Recife e apenas 40km do Porto de Suape, terá forte atuação na logística nordestina. O imóvel seguirá o padrão AAA, com sistema de incêndio J4, pé direito de 12m, controle térmico e de luminosidade e altas tecnologias construtivas emplacadas. 
      `,
    asset_type: "Galpão Logístico CLRE",
    abl: '60.000m²',
    total_area: '120.000 m²',
    complementary_area: 'Previsão de Conclusão: 2º semestre de 2025',
    complementary_area_title: '',
    map_address: 'Rua Projetada 07, s/n – Muribeca – Jaboatão dos Guararapes/PE',
    map_link: "",
    asset_image: "empreendimentos/jabotao_1 - photo.jpg",
    asset_uppperView: null,
    asset_galery_images: [
      "empreendimentos/jabotao_1 - photo.jpg",
      "empreendimentos/jabotao_2 - photo.jpg",
      "empreendimentos/jabotao_3 - photo.jpg",
      "empreendimentos/jabotao_4 - photo.jpg",
      "empreendimentos/jabotao_5 - photo.jpg",
      "empreendimentos/jabotao_6.jpg",
      "empreendimentos/jabotao_7.jpg",
    ]
  },

  {
    asset_id: 16,
    asset_name: "CLMA 01",
    asset_title: "CLMA 01",
    asset_status:"Obras em andamento",
    asset_status_color:'BTSOlive',
    asset_city: "Marília",
    asset_state: "SP",
    asset_details: `
      Galpão projetado para um player importante do setor varejista e cliente recorrente da BTS. Edifício no padrão BTS, AAA, em formato cross docking, com 12m de pé direito e capacidade de piso de 6 ton/m². Mais um empreendimento de impacto para compor a malha logística do país.

      `,
    asset_type: "Galpão Logístico CLMA",
    abl: '50.000m²',
    total_area: '137.000 m²',
    complementary_area: '',
    complementary_area_title: '',
    map_address: 'Av. Eugênio Coneglian, s/n – Distrito Industrial – Marília/SP',
    map_link: "https://maps.app.goo.gl/KLiPaAMhMtMEVYiH8",
    asset_image: "empreendimentos/BTS_MARILIA_C_1 - 4K.jpg",
    asset_uppperView: null,
    asset_galery_images: [
      "empreendimentos/BTS_MARILIA_C_1 - 4K.jpg",
      "empreendimentos/BTS_MARILIA_C_2 - Photo 4k.jpg",
      "empreendimentos/BTS_MARILIA_C_3 - Photo 4k.jpg",
      "empreendimentos/BTS_MARILIA_C_4 - Photo 4k.jpg",
      "empreendimentos/BTS_MARILIA_C_5 - Photo 4k.jpg",
      "empreendimentos/BTS_MARILIA_C_8 - Photo 4k.jpg",
      "empreendimentos/CLMA_2025_01_03_007.jpg",
      "empreendimentos/CLMA_2025_01_03_008.jpg",
      "empreendimentos/CLMA_2025_01_03_010.jpg",
      "empreendimentos/CLMA_2025_01_03_012.jpg",
    ]
  },

  
  {
    asset_id: 15,
    asset_name: "CLSU 01",
    asset_title: "CLSU 01",
    asset_status:"100% Locado!",
    asset_status_color:'BTSBabyBlue',
    asset_city: "Sumaré",
    asset_state: "SP",
    asset_details: `
     Galpão com mais de 70mil m², localizado na cidade de Sumaré/SP e desenvolvido para monousuário do setor varejista. Até a data de sua entrega, se tratava do maior projeto de transportes do interior de São Paulo. Esse imponente imóvel conta com certificação LEED Gold, piso com capacidade de 6 ton/m², pé direito de 12m, controle térmico através de telhas facefelt e iluminação natural favorecida por telhas zenitais.

O imóvel, teve a fase 01 entregue em 7 meses a partir da assinatura do contrato com o cliente e a fase 02 foi entregue em menos dois meses após a entrega da fase 01. Empreendimento construído do zero, sob medida para o cliente e em tempo recorde.
      `,
    asset_type: "Galpão Logístico CLSU",
    abl: '72.000m²',
    total_area: '152.000 m²',
    complementary_area: '',
    complementary_area_title: '',
    map_address: 'Rod. Adauto Campo Dall’Orto, nº 2000 – Sumaré/SP',
    map_link: "https://maps.app.goo.gl/tes25Xcif6oX39S68",
    asset_image: "empreendimentos/CLSU-1.jpg",
    asset_uppperView: null,
    asset_galery_images: [
      "empreendimentos/CLSU-1.jpg",
      "empreendimentos/CLSU-2.jpg",
      "empreendimentos/CLSU-3.jpg",
      "empreendimentos/CLSU-4.jpg",
    ]
  },

  
  {
    asset_id: 14,
    asset_name: "CLRP 01",
    asset_status:"100% Locado!",
    asset_status_color:'BTSBabyBlue',
    asset_title: "CLRP 01",
    asset_city: "Cravinhos",
    asset_state: "SP",
    asset_details: `
     Galpão logístico monousuário entregue em 2024. Desenvolvido para atender a operação de uma empresa inovadora e recém-chegada ao país. Tudo pensado e construído para favorecer e facilitar a operação logística da multinacional.
      `,
    asset_type: "Galpão Logístico CLRP",
    abl: '27.000m²',
    total_area: '63.000 m²',
    complementary_area: '',
    complementary_area_title: '',
    map_address: 'Av. Roberto Gusmão, 1000 – Distrito Industrial – Cravinhos/SP',
    map_link: "",
    asset_image: "empreendimentos/CLRP-1.jpg",
    asset_uppperView: null,
    asset_galery_images: [
      "empreendimentos/CLRP-1.jpg",
      "empreendimentos/CLRP-2.jpg",
      "empreendimentos/CLRP-3.jpg",
      "empreendimentos/CLRP-4.jpg",
      "empreendimentos/CLRP-5.jpg",
      "empreendimentos/CLRP-6.jpg",
      "empreendimentos/CLRP-7.jpg",
    ]
  },

  

  {
    asset_id: 12,
    asset_name: "CLCR 01",
    asset_title: "CLCR 01",
    asset_status: "100% locado",
    asset_status_color:'BTSBabyBlue',
    asset_city: "Cariacica",
    asset_state: "ES",
    asset_details: `
      Condomínio Logístico construído para as empresas Arezzo, Suzano e Whirpool. Empreendimento se encontra na cidade de Cariacica/ES e atualmente é o nosso maior ativo.
      Construção em padrão AAA, desenvolvida para atender especificamente a operação logística dos clientes. 
      `,
    asset_type: "Condomínio Logístico CLCR",
    abl: '115.000m²',
    total_area: '266.000 m²',
    complementary_area: '',
    complementary_area_title: '',
    map_address: 'Rod. BR 101, KM 280 – Cariacica/ES',
    map_link: "",
    asset_image: "empreendimentos/CLCR-1.jpg",
    asset_uppperView: null,
    asset_galery_images: [
      "empreendimentos/CLCR-1.jpg",
      "empreendimentos/CLCR-2.jpg",
      "empreendimentos/CLCR-3.jpg",
      "empreendimentos/CLCR-4.jpg",
      "empreendimentos/CLCR-5.jpg",
      "empreendimentos/CLCR-6.jpg",
    ]
  },

  {
    asset_id: 11,
    asset_name: "Petlove",
    asset_title: "Centro de Distribuição Petlove",
    asset_status: "100% locado",
    asset_status_color:'BTSBabyBlue',
    asset_city: "Itapeva",
    asset_state: "MG",
    asset_details: `
      Empreendimento localizado em Itapeva, cidade próxima ao limite entre os estados de MG e SP. Estrategicamente localizado, visando benefícios tributários e logísticos para o cliente. Galpão desenvolvido especificamente para a operação do cliente, padrão AAA, pé direito de 12m, piso industrial com capacidade para 6 ton./m².
      `,
    asset_type: "Centro de Distribuição",
    abl: '60.000m²',
    total_area: '252.000 m²',
    complementary_area: '',
    complementary_area_title: '',
    map_address: 'Estrada Municipal do Bairro do Mandu, s/n – Mandu – Itapeva/MG',
    map_link: "https://maps.app.goo.gl/eYW2VDRQpvjjfVse8",
    asset_image: "empreendimentos/CLI01_DRONE_006.JPG",
    asset_uppperView: null,
    asset_galery_images: [
      "empreendimentos/CLI01_DRONE_006.JPG",
      "empreendimentos/CLI01_DRONE_044.JPG",
      "empreendimentos/CLI01_DRONE_045.JPG",
      "empreendimentos/CLI01_DRONE_048.JPG",
      "empreendimentos/CLI01_DRONE_052.JPG",
    ]
  },

  {
    asset_id: 10,
    asset_name: "CLPA 02",
    asset_title: "CLPA 02",
    asset_status: "100% locado",
    asset_status_color:'BTSBabyBlue',
    asset_city: "Pouso Alegre",
    asset_state: "MG",
    asset_details: `
      Empreendimento ocupado em parceria entre as empresas Mercado Livre e Solística. Galpão padrão AAA feito sob medida para os clientes.
      `,
    asset_type: "Condomínio Logístico Pouso Alegre 02",
    abl: '9000 m²',
    total_area: '24.000 m²',
    complementary_area: '',
    complementary_area_title: '',
    map_address: 'Av. dos Alecrins, 8000 - Distrito Industrial - Pouso Alegre/ MG',
    map_link: "https://maps.app.goo.gl/5LdSBVqHknoikcbv5",
    asset_image: "empreendimentos/CLPA02-1.jpg",
    asset_uppperView: null,
    asset_galery_images: [
      "empreendimentos/CLPA02-1.jpg",
      "empreendimentos/CLPA02-2.jpg",
      "empreendimentos/CLPA02-3.jpg"
    ]
  },

  {
    asset_id: 9,
    asset_name: "Maple Bear Alphaville",
    asset_title: "Maple Bear - Alphaville Goiânia",
    asset_status: "100% locado",
    asset_status_color:'BTSBabyBlue',
    asset_city: "Goiânia",
    asset_state: "GO",
    asset_details: `

      `,
    asset_type: "Educacional",
    abl: '1.250,00 m²',
    total_area: '1.500,00 m²',
    complementary_area: '',
    complementary_area_title: '',
    map_address: 'Maple Bear Goiânia - Alphaville - Rua Aurilândia - Alphaville Flamboyant, Goiânia - GO',
    map_link: "https://maps.app.goo.gl/rsv1hitpAhp6XxDP6",
    asset_image: "empreendimentos/MBGO-Frente-2.jpg",
    asset_uppperView: null,
    asset_galery_images: [
      "empreendimentos/MBGO-Frente-2.jpg",
      "empreendimentos/MBGO-Frente-1.jpg"
    ]
  },

  {
    asset_id: 8,
    asset_name: "Campus Anhanguera",
    asset_title: "Campus Anhanguera",
    asset_status: "100% locado",
    asset_status_color:'BTSBabyBlue',
    asset_city: "Belo Horizonte",
    asset_state: "MG",
    asset_details: `

      `,
    asset_type: "Educacional",
    abl: '5.497,00 m²',
    total_area: '5.684,00 m²',
    complementary_area: '5.751,00 m²',
    complementary_area_title: 'Área do Terreno',
    map_address: 'R. Alentejo, 1360 - São Francisco - Belo Horizonte - MG, 31255-110',
    map_link: "https://maps.app.goo.gl/Y8q363t2uQD2VbkE9",
    asset_image: "empreendimentos/Anhanguera Frente.jpg",
    asset_uppperView: null,
    asset_galery_images: [
      "empreendimentos/Anhanguera1.jpg",
      "empreendimentos/Anhanguera2.jpg",
      "empreendimentos/Anhanguera Frente.jpg"
    ]
  },

  
  {
    asset_id: 7,
    asset_name: "Burguer King Catalão",
    asset_title: "Burger King Unidade Catalão",
    asset_status: "100% locado",
    asset_status_color:'BTSBabyBlue',
    asset_city: "Belo Horizonte",
    asset_state: "MG",
    asset_details: `

      `,
    asset_type: "Comercial",
    abl: '279,58 m²',
    total_area: '286,13 m²',
    complementary_area: '107,83 m²',
    complementary_area_title: 'Área de Venda',
    map_address: 'Burger King - Av. Presidente Carlos Luz, 168 - Caiçaras, Belo Horizonte - MG, 31250-810',
    map_link: "https://maps.app.goo.gl/yK6gFMwmeEP82HKw8",
    asset_image: "empreendimentos/BKCM-Frente-1.jpg",
    asset_uppperView: null,
    asset_galery_images: [
      "empreendimentos/bkcm-frente-2-992x557.jpg",
      "empreendimentos/bkct-frente-1-1696x954.jpg",
      "empreendimentos/bkct-frente-2-992x558.jpg"
    ]
  },

  {
    asset_id: 6,
    asset_name: "CLPA 01",
    asset_title: "CLPA01",
    asset_status: "100% locado",
      asset_status_color:'BTSBabyBlue',
    asset_city: "Pouso Alegre",
    asset_state: "MG",
    asset_details: `
      O CLPA 01 é o primeiro Centro Logístico desenvolvido em Pouso Alegre/MG pela BTS.
      Atualmente conta com 3 módulos ocupados por FEDEX, Crown Lift Trucks e Sul Gerais.
      `,
    asset_type: "Condomínio Logístico Pouso Alegre 01",
    abl: '8.610,68 m²',
    total_area: '8.653,53m²',
    complementary_area: '17.122,00 m²',
    complementary_area_title: 'Área Terreno',
    map_address: 'Av. das Quaresmeiras, 200, Distrito Industrial - Pouso Alegre, Minas Gerais',
    map_link: "https://maps.app.goo.gl/SQcC9PorjWVozBFp6",
    asset_image: "/empreendimentos/destaque.jpg",
    asset_uppperView: null,
    asset_galery_images: [
      "empreendimentos/clpa111.jpg",
      "empreendimentos/clpa11.jpg",
      "empreendimentos/clpa1.jpg",
    ]
  },

  {
    asset_id: 5,
    asset_name: "Centro de Distribuição Panasonic",
    asset_title: "CD Panasonic",
    asset_status: "100% locado",
    asset_status_color:'BTSBabyBlue',
    asset_city: "Itapeva",
    asset_state: "MG",
    asset_details: `
      Centro de Distribuição Logístico locado para Panasonic do Brasil por meio de Contrato de Locação Built-to-Suit, o imóvel se encontra na cidade de Itapeva/MG, localizado próximo à fábrica de produtos da linha branca o CD é responsavel pela estocagem e atendimento direto ao mercado sudeste, além de servir de apoio à outras regiões do país.

      Construção realizada sob medida para atendimento da demanda da Panasonic, possibilitando aderência ao seu processo de trabalho e ao planejamento estratégico da empresa, inclusive com entregas escalonadas.
      `,
    asset_type: "Logístico",
    abl: '23.453,67 m²',
    total_area: '23.453,67 m²',
    complementary_area: '21.825,51 m²',
    complementary_area_title: 'Área de Armazenagem',
    map_address: 'Panasonic Itapeva - Estrada Municipal do Mandu, 37655-000, Itapeva - MG',
    map_link: "https://maps.app.goo.gl/XLXb49HW1vupjjAn9",
    asset_image: "/empreendimentos/Panasonic-Lateral-1 (1).jpg",
    asset_uppperView: null,
    asset_galery_images: [
      "empreendimentos/Panasonic-Entrada-01.jpg",
      "empreendimentos/Panasonic-Escritorio-1.jpg",
      "empreendimentos/Panasonic-Implantacao-01.jpg",
      "empreendimentos/Panasonic-Interna-1.jpg",
      "empreendimentos/Panasonic-Lateral-1 (1).jpg",
      "empreendimentos/Panasonic-Lateral-2.jpg"
    ]
  },

  {
    asset_id: 4,
    asset_name: "Telhanorte Unidade Amazonas",
    asset_title: "Telhanorte Amazonas",
    asset_status: "100% locado",
    asset_status_color:'BTSBabyBlue',
    asset_city: "Belo Horizonte",
    asset_state: "MG",
    asset_details: `
      Desenvolvida sob medida para a Telhanorte na modalidade Built-to-Suit, a Telhanorte Amazonas conta com uma localização estratégica, esquina entre a Av. Amazonas e a Av. Silva Lobo, um salão de vendas com mais de 3.850 m² e 204 vagas de estacionamento. Tudo isso, possibilita que a loja consiga atender de forma confortável todo o fluxo de clientes. Essa foi 4ª loja do grupo a ser implantada em BH e região metropolitana.
      `,
    asset_type: "Comercial",
    abl: '4.755,00 m²',
    total_area: '14.317,38 m²',
    complementary_area: '3.876,84 m²',
    complementary_area_title: 'Área de Venda',
    map_address: 'Telhanorte Amazonas, Av. Amazonas, 4368 - Nova Suíça, Belo Horizonte - MG, 30180-001',
    map_link: "https://maps.app.goo.gl/1ZVvCvojZUVBM3V56",
    asset_image: "/empreendimentos/Telhanorte-Fachada-2 (2).jpg",
    asset_uppperView: null,
    asset_galery_images: [
      "empreendimentos/Telhanorte-Fachada-1.jpg",
      "empreendimentos/Telhanorte-Fachada-2 (2).jpg",
      "empreendimentos/Telhanorte-Fachada-3.jpg"
    ]
  },

  {
    asset_id: 3,
    asset_name: "Fábrica Aryzta",
    asset_title: "Fabrica Bimbo QSR",
    asset_status: "100% locado",
    asset_status_color:'BTSBabyBlue',
    asset_city: "Pouso Alegre",
    asset_state: "MG",
    asset_details: `
      A Aryzta, empresa suíça líder mundial em panificação congelada, assume nesta sexta-feira (25), o prédio de sua quinta e maior fábrica no Brasil, em projeto que foi desenvolvido em conjunto com a BTS, especializada em desenvolvimento de empreendimentos built-to-suit. Com investimento total de mais de R$ 300 milhões e geração de 300 empregos diretos e aproximadamente 1.000 indiretos, a nova fábrica de Pouso Alegre, sul de Minas Gerais, terá a maior linha da empresa em todos os 19 países onde atua.
      `,
    asset_type: "Fábrica",
    abl: '18.713,63 m²',
    total_area: '18.713,63 m²',
    complementary_area: '53.622,98 m²',
    complementary_area_title: 'Área da Armazenagem',
    map_address: 'Colina Bandeirantes, Pouso Alegre - MG, 37550-000',
    map_link: "https://maps.app.goo.gl/HNH7SLZW6sMC8Wx67",
    asset_image: "/empreendimentos/Bimbo1.jpeg",
    asset_uppperView: null,
    asset_galery_images: [
      "empreendimentos/Bimbo1.jpeg",
      "empreendimentos/Bimbo2.jpeg",
      "empreendimentos/Bimbo3.jpeg"
    ]
  },

  {
    asset_id: 2,
    asset_name: "Centro de Distribuição Cremer",
    asset_title: "CD Cremer",
    asset_status: "100% locado",
    asset_status_color:'BTSBabyBlue',
    asset_city: "Pouso Alegre",
    asset_state: "MG",
    asset_details: `
      Centro de Distribuição Logístico locado para Cremer S.A. por meio de Contrato de Locação Built-to-Suit, o imóvel se encontra na cidade de Pouco Alegre/MG, uma localização estratégica proporcionando atendimento e entregas rápidas aos mercados Mineiro, Paulista e Carioca.

      Construção realizada sob medida para atendimento da demanda da Cremer possibilitando aderência ao seu processo de trabalho e ao planejamento estratégico da empresa por um longo período de tempo.
      `,
    asset_type: "Logístico",
    abl: '13.899,36 m²',
    total_area: '13.899,36 m²',
    complementary_area: '12.672,89 m²',
    complementary_area_title: 'Área de Armazenagem',
    map_address: 'Av. das Quaresmeiras, 200, Distrito Industrial - Pouso Alegre, Minas Gerais',
    map_link: "https://maps.app.goo.gl/8rsAFZaQA1Tgd8zx6",
    asset_image: "/empreendimentos/Cremer-PA---Drone-Frente---2.jpg",
    asset_uppperView: null,
    asset_galery_images: [
      "empreendimentos/Cremer PA Drone Frente 2 (1).jpg",
      "empreendimentos/Cremer-PA-Drone-Frente.jpg"
    ]
  },

  

  {
    asset_id: 1,
    asset_name: "Upper Mall Sion",
    asset_status: "100% locado",
    asset_status_color:'BTSBabyBlue',
    asset_title: "Upper Mall Sion",
    asset_city: "Belo Horizonte",
    asset_state: "MG",
    asset_details: `
      O BTS Upper Mall Sion é um empreendimento desenvolvido pela BTS Properties, em parceria com a Metron, ancorado na sua maioria por operações de Built-to-Suit, com o conceito de agregar várias lojas ancora em um único edifício. 

      O projeto arquitetônico foi desenvolvido em parceria com o escritório de arquitetura Farkasvölgyi e foi totalmente concebido para ser harmônico com o movimento e a velocidade da Avenida Nossa Senhora do Carmo. 

      Localizado na principal avenida de ligação da região centro-sul, uma das áreas mais nobres de Belo Horizonte, com uma vista para Serra do Curral, o Upper Mall Sion conta com 8 andares, sendo 4 andares de estacionamento para 326 veículos e 4 andares de lojas.. 
`,
    asset_type: "Centro Comercial Vertical - UpperMall",
    abl: '1.095,06 m² (Cobasi) + 5.013,90 m² (Decathlon) + 2.527,43 m² (1001 Festas) = 8.636,39 m²',
    total_area: '18.044,89m²',
    complementary_area: '7.062,27 m²',
    complementary_area_title: 'Área Terreno',
    map_address: 'Av. Nossa Senhora do Carmo, 1700 - Sion - Belo Horizonte/MG',
    map_link: "https://maps.app.goo.gl/RRik1CCtQiSaRrXz8",
    asset_image: "/empreendimentos/2 (1).jpg",
    asset_uppperView: null,
    asset_galery_images: [
      "/empreendimentos/2.jpg",
      "/empreendimentos/3.jpg"
    ]
  },
  {
    asset_id: 0,
    asset_name: "Centro de Distribuição Dafiti",
    asset_status: "100% locado",
    asset_status_color:'BTSBabyBlue',
    asset_title: "CD Dafiti",
    asset_city: "Belo Horizonte",
    asset_state: "MG",
    asset_details: `
      Centro de Distribuição Logístico locado para a Dafiti por meio de Contrato de Locação na modalidade Built-to-Suit, o imóvel se encontra na cidade de Extrema/MG. Atualmente é o maior galpão em operação pela companhia.

      Construção realizada customizada para atendimento da demanda da Dafiti e recebimento de um dos sistemas de automação mais modernos do mundo, possibilitando aderência ao seu plano de crescimento e uma operação mais eficiente, já que foi inteiramente pensado de acordo com o seu fluxo operacional.
      `,
    asset_type: "Logístico",
    abl: '77.000,00 m²',
    total_area: '78.000,00 m²',
    complementary_area: '7.062,27 m²',
    complementary_area_title: 'Área Terreno',
    map_address: 'Estrada Municipal Luiz Lopes Neto, 617 – Bairro dos Tenentes, Extrema, MG - CEP: 37640-000',
    map_link: "https://maps.app.goo.gl/vKF3C86iG3VLLskD6",
    asset_image: "/empreendimentos/Dafiti-Frente-3 (1).jpg",
    asset_uppperView: "/empreendimentos/Dafiti-Implantacao (1).jpg",
    asset_galery_images: [
      "/empreendimentos/Dafiti-Frente-1.jpg",
      "/empreendimentos/Dafiti-Frente-2.jpg",


    ]
  }, 
];

export default Assets;