import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-white py-4 border-t flex justify-center">
      <div className="m-4 sm:m-8 md:m-16 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 items-center gap-4 max-w-screen-xl mx-auto">

        {/* Logo BTS */}
        <div className="flex justify-center md:justify-start">
          <img src="logo-bts.png" alt="Logo BTS" className="h-10 sm:h-12 md:h-14" />
        </div>

        {/* Links e copyright */}
        <div className="text-center text-sm sm:text-base md:text-lg flex flex-col items-center">
          <div className="flex flex-wrap justify-center space-x-4 mb-2">
            
            {/* Scroll para o header */}
            <a onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} className="text-black font-light hover:font-bold transition">
              Início
            </a>

            {/* Link para trabalhe conosco */}
            <a href="https://bts.gupy.io/" className="text-black font-light hover:font-bold transition">
              Trabalhe conosco
            </a>
          </div>
          <p className="text-BTSOlive text-[10px] sm:text-xs md:text-sm lg:text-base whitespace-nowrap">
           COPYRIGHT 2025 - BTS PROPERTIES - TODOS OS DIREITOS RESERVADOS
          </p>

        </div>

        {/* Site por */}
        <div className="flex justify-center md:justify-end items-center space-x-2">
          <span className="text-BTSOlive text-xs sm:text-sm md:text-base">Site por:</span>
          <img src="bts-simbolo.png" alt="BTS Simbolo" className="h-4 sm:h-5 md:h-6" />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
