import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';

const SlideView = (props) => {
  const [fullScreenImage, setFullScreenImage] = useState(null);

  const handleImageClick = (image) => {
    setFullScreenImage(image); // Abre a imagem selecionada
  };

  const closeFullScreen = () => {
    setFullScreenImage(null); // Fecha a imagem em tela cheia
  };

  return (
    <div className="container mx-auto my-10 w-full max-w-6xl">
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={3}
        spaceBetween={-30}
        coverflowEffect={{
          rotate: 30,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        pagination={{ clickable: true }}
        navigation={true}
        modules={[EffectCoverflow, Pagination, Navigation]}
        className="mySwiper"
      >
        {props.slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <img
              src={slide}
              alt={`slide-${index}`}
              className="w-full lg:h-64 object-cover rounded-lg shadow-lg cursor-pointer"
              onClick={() => handleImageClick(slide)} // Evento onClick
            />
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Modal para exibir a imagem em tela cheia */}
      {fullScreenImage && (
        <div
          className="fixed inset-0 bg-black bg-opacity-90 flex items-center justify-center z-50"
          onClick={closeFullScreen}
        >
          <img
            src={fullScreenImage}
            alt="Imagem em tela cheia"
            className="max-w-full max-h-full"
          />
          <button
            className="absolute top-4 right-4 text-white text-3xl"
            onClick={closeFullScreen}
          >
            &times;
          </button>
        </div>
      )}
    </div>
  );
};

export default SlideView;
