import { useState } from "react";

const Denuncia = ()=>{

       const [email,setEmail] = useState('');
        const [name,setName] = useState('');
        const [phone,setPhone] = useState('');
        const [message,setMessage] = useState('');
        const sendForm = async () => {
            try {
                // Configuração da requisição para a API
                const response = await fetch('http://localhost:3001/api/sendMail', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        to: 'thiago.oliveira@btsp.com.br',
                        subject: `Denuncia de ${name}`,
                        body: `
                            Nome: ${name}
                            Email: ${email}
                            Telefone: ${phone}
                            Mensagem: ${message}
                        `
                    })
                });
        
                const result = await response.json();
                console.log(response.status);  // Log da resposta do servidor
     
                if (response.status == 200) {
                    setEmail('');
                    setName('');
                    setPhone('');
                    setMessage('');
                    console.log("Mensagem enviada com sucesso.");
                } else {
                    console.error("Erro ao enviar a mensagem:", result.message || "Tente novamente.");
                    alert("Erro ao enviar a mensagem. Tente novamente.");
                }
            } catch (error) {
                console.error(error);
                alert("Ocorreu um erro ao enviar a mensagem.");
            }
        };

return(

    <div className="bg-DarkWine p-10 py-36">

        <div className="lg:text-4xl font-bold mb-10 text-3xl" id="contatoTitle">Denúncia</div>
        <div className="flex lg:px-72 font-thin text-lg leading-8">
        A BTS fornece um canal exclusivo para que colaboradores, parceiros, clientes e fornecedores possam reportar, 
        de forma segura, quaisquer condutas que violem nossos princípios éticos, políticas internas, 
        legislações aplicáveis ou que possam comprometer a integridade de nossa organização.
        </div>
        <form className="w-full justify-center text-gray-800 font-thin mt-5 text-lg">
        <div className="flex justify-center flex-col lg:px-96">
        <input id="Email" placeholder="Email" type="email" className="rounded-full my-2 p-3 text-center" value={email} onChange={(e)=>setEmail(e.target.value)}></input>
        <input id="Nome" placeholder="Nome" type="text" className="rounded-full my-2 p-3 text-center" value={name} onChange={(e)=>setName(e.target.value)}></input>
        <input id="Telefone" placeholder="Telefone" type="tel" className="rounded-full my-2 p-3 text-center" value={phone} onChange={(e)=>setPhone(e.target.value)}></input>
        <textarea id="Mensagem" placeholder="Mensagem" className="rounded-2xl my-2 p-2 text-center" rows="5" value={message} onChange={(e)=>setMessage(e.target.value)}></textarea>


        <button className="bg-slate-600 font-bold text-2xl rounded-full p-2 mt-3 text-white" onClick={(e)=>{sendForm()}}>Enviar</button>
        </div>
        </form>

    </div>

)

}

export default Denuncia;