import React from "react";

const Header = () => {
    return (
        <header className="bg-white p-6 sm:p-8 lg:p-10">
            {/* Logo BTS */}
            <div className="mt-6 sm:mt-8 lg:mt-10 flex justify-center">
                <img src="logo-bts.png" alt="logo BTS" className="h-12 sm:h-14 w-auto" />
            </div>

            {/* Texto do Header */}
            <div className="pt-12 sm:pt-16 lg:pt-20 text-center">
                <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold text-black">
                    Sua melhor experiência
                </h1>
                <h1 className="text-4xl sm:text-5xl lg:text-6xl font-light text-black mt-2">
                    em projetos imobiliários
                </h1>
            </div>

            {/* Imagens e serviços */}
            <div className="w-full mt-12 sm:mt-16 lg:mt-20 px-6 sm:px-10 lg:px-20 grid  grid-cols-3 gap-6 items-center justify-items-center">
                <div className="text-center">
                    <img src="industrial.png" alt="img1" className="h-16 sm:h-20 w-auto mx-auto" />
                    <p className="mt-4 text-base sm:text-lg font-extrabold text-BTSOlive">Industrial</p>
                </div>
                <div className="text-center">
                    <img src="logistico.png" alt="img2" className="h-16 sm:h-20 w-auto mx-auto" />
                    <p className="mt-4 text-base sm:text-lg font-extrabold text-BTSOlive">Logístico</p>
                </div>
                <div className="text-center">
                    <img src="Comercial.png" alt="img3" className="h-16 sm:h-20 w-auto mx-auto" />
                    <p className="mt-4 text-base sm:text-lg font-extrabold text-BTSOlive">Comercial</p>
                </div>
            </div>
        </header>
    );
};

export default Header;
